
import React from "react"

import { MainLayout } from "../layouts/main.layout"
import StandardPageHeader from "../components/layout-components/top/headers/standard-page-header/StandardPageHeader.component"
import PageSection from "../layouts/PageSection/PageSection.component"
import Footer from "../components/layout-components/bottom/footer/Footer.component"
import { getCanonicalUrl } from "../utils/getCanonicalUrl"

const pageSlug = "quizes";

export default () => (

  <MainLayout canonicalUrl={getCanonicalUrl(pageSlug)}>
    


    {/* 
    
          Header

    */}

    <StandardPageHeader 
      data={
        {
          heading: "Quizes",
          backgroundImage: "/unsplash-photo-1486312338219-ce68d2c6f44d-d.jpeg"
        }
      } 
    />


    
    {/* 
    
          Quizes

    */}

    <PageSection>

      <h3 style={{padding: "195px 0", textAlign: "center"}}>Page under construction :)</h3>

    </PageSection>



    {/* 

          Footer
    
    */}

    <Footer />

  </MainLayout>
      
)